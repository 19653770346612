body {
  .needs-mobile-padding {
    @media screen and (max-device-width: $breakpoint-sm) {
      padding-top: $navbar-padding;
    }
  }
}
.light-gray-pane {
  padding-bottom: $spacing-l;
  background-color: #f5f5f5;
}

.white-pane {
  padding-bottom: $spacing-l;
  background-color: white;
}
