.CmsContent{
  p {
    margin-bottom: 20px;
    strong {
      @include open-sans-condensed-bold-uppercase();
    }
  }
  h3 {
    margin-top: 32px;
    margin-bottom: 18px;
  }
  ul {
    font-size: 16px;
    margin-bottom: 32px;
    li {
      font-weight: 300;
    }
  }
}
