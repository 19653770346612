.ProjectDetails {
  margin-bottom: $spacing-xl;
  h1 {
    @include open-sans-condensed-bold-uppercase;
    color: $brand-gray-darkest;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0;
    @media screen and (max-device-width: $breakpoint-sm) {
      font-size: 26px;
    }
  }
  h2 {
    font-size: 46px !important;
    text-align: center;
  }
  .navigation {
    padding: 0;
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
    border: none;
    button, a {
      @include open-sans-condensed-bold-uppercase;
      @media screen and (max-device-width: $breakpoint-sm) {
        margin: 0;
      }
      padding: 14px 30px;
      border: none;
      outline: none;
      color: white;
      background-color: $form-button-enabled;
      &.disabled {
        cursor: default;
        background-color: $form-button-disabled;
      }
    }
   &--bottom {
     margin-top: 0;
    }
  }
  .gray-panel {
    display: flex;
    flex-direction: column;
    background-color: $brand-gray-light;
    margin-bottom: 40px;
    .header {
      @include open-sans-condensed-bold-uppercase;
      background-color: $brand-orange;
      padding: 18px;
      margin: 10px;
    }
    .body {
      margin: 10px;
      padding: 18px;
      dl {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        dt, dd {
          margin-bottom: 20px;
        }
        dt {
          @include open-sans-semibold;
          flex-basis: 20%;
          @media screen and (max-device-width: $breakpoint-sm) {
            flex-basis: 28%;
          }
          display: inline-block;
          width: 180px;
        }
        dd {
          display: initial;
          flex-basis: 70%;
          @media screen and (max-device-width: $breakpoint-sm) {
            flex-basis: 60%;
          }
          flex-grow: 1;
          /*
          &::after {
            content: '\A';
            white-space: pre;
          }
          */
        }
      }
      a {
        color: $brand-blue;
        text-decoration: none;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
}

