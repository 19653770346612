.PartnerCard {
  padding: $spacing-l;
  background-color: white;
  max-width: 840px;
  width: 65vw;
  margin: 25px;
  @media screen and (min-device-width: $breakpoint-xs) and (max-device-width: $breakpoint-md) {
    width: 75vw;
    margin: 20px;
  }
  @media only screen and (max-device-width: $breakpoint-xs) {
    width: 80vw;
    margin: 16px;
    padding: 24px;
  }
  .partner-name {
    margin-top: $spacing-m;
    @media only screen and (max-device-width: $breakpoint-xs) {
    }
  }
  .partner-text {
    font-size: 16px;
    line-height: 28px;
    font-weight: 300;
    margin-bottom: 0;
    @media only screen and (max-device-width: $breakpoint-xs) {
      font-size: 14px;
      line-height: 22px;
    }
  }
  .partner-logo {
    display: block;
    margin: auto;
  }
}
