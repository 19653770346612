$li-vertical-padding: 10px;
$li-horizontal-padding: 12px;
$li-horizontal-padding-half: 6px;
.ProjectFilters {
  margin-bottom: $spacing-xl;
  h1 {
    @include open-sans-condensed-bold-uppercase;
    color: $brand-gray-darkest;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0;
    @media screen and (max-device-width: $breakpoint-sm) {
      font-size: 26px;
    }
  }
  h2 {
    font-size: 22px !important;
    text-align: left;
    margin-top: 10px !important;
  }
  h3 {
    @include open-sans-semibold;
    text-transform: capitalize;
    font-size: 16px !important;
  }
  ul {
    list-style: none;
    padding-left: 0;
    li {
      padding: $li-horizontal-padding-half $li-horizontal-padding ;
      border-bottom: 1px solid white;
      &:last-child {
        border-bottom: 1px solid white;
      }
      &.complex-parent {
        margin: 0;
        padding: 10px 0;
        border-bottom: none;
        .complex-header {
          @include open-sans-semibold;
          font-weight: 700 !important;
          padding-left: $li-horizontal-padding;
          margin: 0;
        }
        ul {
          li {
            display: flex;
          }
        }
      }
      label {
        padding-left: $li-horizontal-padding;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  .extra-filters {
    ul {
      li {
        display: flex;
      }
    }
  }

  .input-wrapper {
    margin: $li-horizontal-padding-half $li-horizontal-padding;
    input[type="text"] {
      @include textInput;
    }
  }
  .row {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .col-md-3, .col-md-4, .col-md-9, .col-md-12 {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .gray-panel {
    display: flex;
    flex-direction: column;
    background-color: $brand-gray-light;
    margin-bottom: 10px;
    .header {
      @include orangeHeader;
      &.simple-header {
        margin: 10px 0 !important; //Footer headers are also compact, but have a different structure
      }
      label {
        font-weight: 700 !important;
      }
    }
    div {
      &.header {
        @include open-sans-semibold;
        @include orangeHeader;
        label {
          font-weight: 600;
          font-size: 16px;
        }
        &--no-horizontal-spacing {
          padding: 18px $li-horizontal-padding;
          margin: $li-horizontal-padding 0;
        }
      }
    }
  }
  .actions {
    padding: 0;
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
    border: none;
    button, a.filter {
      @include open-sans-condensed-bold-uppercase;
      @media screen and (max-device-width: $breakpoint-sm) {
        margin: 0 30px;
      }
      margin: 0 $li-vertical-padding;
      padding: 14px 30px;
      border: none;
      outline: none;
      color: white;
      background-color: $form-button-enabled;
      &.disabled {
        background-color: $form-button-disabled;
      }
    }
  }
}

