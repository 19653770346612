.Home {
  h1 {
    @include open-sans-light;
    text-transform: initial;
    font-size: 32px;
    line-height: 46px;
    text-align: center;
    margin-top: $spacing-l;
    margin-bottom: $spacing-m !important;
  }
  .ProjectTypes {
    max-width: 1380px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    @media screen and (max-device-width: $breakpoint-md) {
      flex-wrap: wrap;
    }
    .home-project-types-wrapper {
      display: flex;
      @media screen and (max-device-width: $breakpoint-xs) {
        display: block;
      }
    }
  }
  .FreeLeads {
    background-blend-mode: overlay;
    background-color: $brand-blue-dark;
    background-position: center;
    background-size: cover;
    color: white;
    .title {
      @include open-sans-condensed-bold-uppercase;
      font-size: 44px;
      margin-top: 40px;
      margin-bottom: 10px;
      span {
        color: $brand-orange;
      }
    }
    .form-wrapper {
      background-color: initial;
      .form-header {
        background-color: initial;
        .subtitle {
          text-align: center;
          text-transform: initial;
          font-size: 14px;
          line-height: 20px;
        }
      }
      .form-data {
        input {
          background-color: transparent;
          &::placeholder {
            color: white;
          }
        }
        button {
          &.submit {
            color: $brand-blue-dark;
            background-color: $brand-orange;
          }
        }
      }
    }
  }
}

@media screen and (max-device-width: $breakpoint-xs) {
  .Home {
    h1 {
      font-size: 22px;
      line-height: 36px;
      margin-top: $spacing-40 !important;
    }
    h2 {
      font-size: 26px !important;
      margin-top: $spacing-40 !important;
      margin-bottom: 10px !important;
    }
    .ProjectHoverTiles {
      .hover-tiles{
        margin-bottom: $spacing-l;
      }
    }
    .ProjectTypes {
      max-width: auto;
      margin: auto;
      display: initial;
      .slick-dots {
        position: initial;
        padding-top: 20px;
        padding-bottom: 0;
        button::before {
          font-size: 12px;
          color: $brand-gray-light;
          opacity: 1;
        }
        .slick-active button::before {
          color: $brand-orange;
        }
      }
    }
    .FreeLeads {
      .title {
        font-size: 26px;
        line-height: 38px;
        margin-top: $spacing-40;
        margin-bottom: $spacing-20 !important;
      }
      .form-wrapper {
        .form-header {
          p.subtitle {
            width: 240px;
            margin: 0 auto;
            font-size: 14px;
            line-height: 20px;
          }
        }
        .form-data {
          input {
            margin-bottom: 15px;
          }
          button.submit {
            margin-top: 15px;
          }
        }
      }
    }
    .OurPartnersSimple {
      .title {
        margin-bottom: 0 !important;
      }
    }
  }
}
