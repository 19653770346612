
$brand-gray-light: #ececec;
$brand-gray-darkest: #0a0a0a;
$brand-orange: #fdb82b;
$brand-orange-light: #f3d79f;
$brand-blue: #2699d1;
$brand-blue-dark: #061538;
$brand-text-dark: #0a0a0a;

$brand-blue-faq: #2b6afc;
$brand-link-blue-dark: #57788b;
$brand-quote-blue-dark: #061538;

$brand-input-color: #0a0a0a;
$form-color-link-primary: #337ab7;
$form-color-forgotten-password: #337ab7;

$form-button-disabled: #a7a7a7;
$form-button-enabled: #57788b;

$input-border-color: #d9d9d9;

$navbar-mobile-background: #524f4f
