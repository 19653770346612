$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
@import "~bootstrap-sass/assets/stylesheets/bootstrap.scss";



@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@import 'styles/colors';
@import 'styles/spacing';
@import 'styles/typography';
@import 'styles/layout';
@import 'styles/mixins';

@import 'components/TopBar';
@import 'components/HeroCarousel';
@import 'components/Menu';
@import 'components/HeroFeatures';

@import 'components/TickerBar';
@import 'components/PartnerCard';
@import 'components/TestimonialsHome';
@import 'components/TestimonialCard';

@import 'components/BackLink';
@import 'components/IconCardLeft';
@import 'components/OurPartners';
@import 'components/OurPartnersSimple';
@import 'components/FeaturedContent';
@import 'components/OurApproach';
@import 'components/ProjectHoverTiles';
@import 'components/SimpleTile';
@import 'components/HoverTile';
@import 'components/GetYourLeads';
@import 'components/NavigationFooter';
@import 'components/TermsAndConditionsFooter';

@import 'pages/Home';
@import 'pages/FAQS';
@import 'pages/FAQAnswer';
@import 'pages/Testimonials';
@import 'pages/ContactUs';
@import 'pages/FreeLeads';
@import 'pages/SignInSignUp';
@import 'pages/NationalProjects';
@import 'pages/RegionalProjects';
@import 'pages/BespokeProjects';
@import 'pages/Projects';
@import 'pages/ProjectDetails';
@import 'pages/ProjectFilters';
@import 'pages/Products';
@import 'pages/MyAccount';
@import 'pages/CmsContent';
