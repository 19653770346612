$text-color: #afafaf;
$background-color: #333333;
.TermsAndConditionsFooter {
  text-align: center;
  padding: $spacing-m 0;
  background-color: $background-color;
  color: $text-color;
  font-size: 12px;
  line-height: 24px;
  ul {
    max-width: 1000px;
    padding: 0;
    margin: 0 auto;
    list-style-type: none;
    li {
      display: inline;
      &::before {
        content: ' ';
      }
      &::after {
        content: ' ';
          word-spacing: 1em;
          background-image: linear-gradient($text-color, $text-color);
          background-size: .15ch 1em;
          background-repeat: no-repeat;
          background-position: center center;
      }
      a {
        color: $text-color;
        text-decoration: none;
        white-space: nowrap;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-device-width: $breakpoint-xs) {
  .TermsAndConditionsFooter {
    padding: 20px 0;
  }
}
