.ProjectHoverTiles {
  .hover-tiles {
    margin-bottom: 52px;
    .tiles-wrapper {
      display: flex;
      justify-content: center;
      white-space: nowrap;
      margin: auto;
      .hover-tile {
        display: inline-block;
        padding: 8px;
      }
      .slick-dots {
        bottom: -44px;
        padding-bottom: $spacing-s;
        button::before {
          font-size: 12px;
          color: $brand-gray-light;
          opacity: 1;
        }
        .slick-active button::before {
          color: $brand-orange;
        }
      }
    }
  }
}
