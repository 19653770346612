.HoverTile {
  background-color: rgba(0,0,0, .0);
  background-blend-mode: color;
  position: relative;
  color: white;
  width: 450px;
  height: 0;
  padding-bottom: 70%;
  overflow-y: hidden;
  transition: all 1s;
  white-space: initial;
  img {
    width: 100%;
  }
  .content {
    margin-top: 256px;
    margin-left: $spacing-m;
    margin-right: $spacing-m;
    transition: all 1s;
    @media screen and (max-device-width: $breakpoint-xs) {
      margin-top: 180px;
      margin-left: $spacing-s;
      margin-right: $spacing-s;
    }
    a {
      color: $brand-orange;
      text-decoration: none;
      &::after {
        content: "";
        width: 30px;
        height: 1px;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        background-color: $brand-orange;
      }
    }
    .title {
      @include open-sans-condensed-bold-uppercase;
      font-size: 32px;
      margin-bottom: $spacing-m;
    }
    .description {
      @include open-sans-light;
      font-size: 18px;
    }
    .find-out-more {
      @include open-sans-condensed-bold-uppercase;
      font-size: 16px;
    }
  }
  &:hover {
    background-color: rgba(0,0,0, .5);
    .content {
      margin-top: $spacing-l;
      @media screen and (max-device-width: $breakpoint-xxs) {
        margin-top: $spacing-l;
      }
    }
  }
  @media screen and (max-device-width: $breakpoint-xs) {
    width: 325px;
    .content {
      .title {
        font-size: 24px;
        margin-bottom: $spacing-m;
      }
      .description {
        font-size: 14px;
      }
    }
  }
}
