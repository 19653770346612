$link-color: white;
$span-color: #061538;

.GetYourLeads {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $brand-orange;
  padding: 32px 0;
  p {
    font-family: 'Open Sans Condensed';
    font-size: 32px;
    line-height: 38px;
    font-weight: bold;
    margin-bottom: 0;
    text-align: center;
    text-transform: uppercase;
    a {
      color: $link-color;
      text-decoration: none;
    }
    span {
      color: $span-color;
    }
    svg {
      margin-left: 20px;
      vertical-align: baseline;
    }
  }
}

@media only screen and (max-device-width: $breakpoint-xs) {
  .GetYourLeads {
    p {
      font-size: 26px;
    }
  }
}
