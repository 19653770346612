.TestimonialsHome {
  background-color: $brand-gray-light;
  text-align: center;
  h2 {
    margin-bottom: 16px;
  }
  p {
    font-size: 22px;
    line-height: 36px;
    margin-bottom: 0;
    a {
      color: $brand-blue-faq;
      font-weight: 700;
      @include open-sans-condensed-bold-uppercase;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .slick-slider {
    margin-bottom: initial;
  }
  .slider {
    background-color: $brand-gray-light;
    .my-slide {
      padding: $spacing-l;
      background-color: white;
      max-width: 840px;
      width: 65vw;
      margin: 50px 50px 36px 50px;
      @media screen and (min-device-width: $breakpoint-xs) and (max-device-width: $breakpoint-md) {
        width: 75vw;
        margin: 20px;
      }
      @media only screen and (max-device-width: $breakpoint-xs) {
        width: 80vw;
        margin: 16px;
        padding: 24px;
      }
      .partner-name {
        margin-top: $spacing-m;
        @media only screen and (max-device-width: $breakpoint-xs) {
        }
      }
      .partner-text {
        font-size: 16px;
        line-height: 28px;
        font-weight: 300;
        margin-bottom: 0;
        @media only screen and (max-device-width: $breakpoint-xs) {
          font-size: 14px;
          line-height: 22px;
        }
      }
      .partner-logo {
        display: block;
        margin: auto;
      }
    }
    .slick-dots {
      position: initial;
      padding-bottom: $spacing-l;
      button::before {
        font-size: 12px;
        color: white;
        opacity: 1;
      }
      .slick-active button::before {
        color: $brand-orange;
      }
    }
  }
}
@media only screen and (max-device-width: $breakpoint-xs) {
  .TestimonialsHome {
     p {
       font-size: 16px;
     }
     .slider {
       .slick-dots {
         padding: 16px 0;
         margin-bottom: 0;
       }
     }
  }
}
