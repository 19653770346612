.Menu {
  position:absolute !important;
  z-index: 100;
  background-color: transparent;
  color: white;
  top: $spacing-m;
  right: 0;
  @media screen and (max-device-width: $breakpoint-sm) {
    top: 10px;
    right: 16px;
  }

  &.navbar-default {
    border-color: transparent;
    background-color: transparent;
    .navbar-toggle {
      background-color: transparent;
      border-color: transparent;
      margin-right: 0;
      &:hover{
        background-color: transparent;
      }
      .icon-bar {
        background-color: white;
        &:last-of-type {
          width: 12px;
          float: right;
        }
      }
    }
    .navbar-collapse {
      box-shadow: none;
      border-color: transparent;
      padding-right: 0;
      float: right;
      clear: both;
      @media screen and (max-device-width: $breakpoint-sm) {
        overflow: hidden;
      }
    }
    ul.navbar-nav {
      @media screen and (max-device-width: $breakpoint-sm) {
        margin: 0;
      }
      li {
        display: inline;
        @media screen and (max-device-width: $breakpoint-sm) {
          display: block;
          text-align: right;
          margin-top: 8px;
          margin-right: -4px;
        }
        .dropdown-menu {
          background-color: transparent;
          min-width: max-content;
          li {
            a {
              display: inline;
            }
          }
        }
        &::after {
          content: '|';
          width: auto;
          height: auto;
          vertical-align: top;
          background-color: transparent;
          @media screen and (max-device-width: $breakpoint-sm) {
            content: '';
          }
        }
        &:last-child {
          &::after {
            color: transparent
          }
        }
        a {
          font-size: 18px;
          @include open-sans-condensed-bold-uppercase;
          color: white;
          background-color: transparent;
          margin: 0 1ch;
          padding: 0;
          &:hover {
            text-decoration: underline;
          }
          &::after {
            width: auto;
          }
        }
      }
    }
    // Submenu on hover
    a.dropdown-toggle:hover + ul.dropdown-menu, ul.dropdown-menu:hover { display: block; }
    .dropdown-menu { margin-top: 0; }

  }
}
