.OurApproach {
  h2 {
    margin-top: $spacing-xl;
  }
  .leads-link {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    @media screen and (max-device-width: $breakpoint-xs) {
      font-size: 18px;
      line-height: 18px;
    }
  }
}
