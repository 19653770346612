.MyAccount {
  margin-bottom: $spacing-xl;
  h1 {
    @include open-sans-condensed-bold-uppercase;
    color: $brand-gray-darkest;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0;
    @media screen and (max-device-width: $breakpoint-sm) {
      font-size: 26px;
    }
  }
  h2 {
    font-size: 46px !important;
    text-align: center;
  }
  .account-data {
    background-color: $brand-gray-light;
    padding: 48px 20px;
    dl {
      dt, dd {
        margin-bottom: 20px;
      }
      dt {
        display: inline-block;
        width: 180px;
      }
      dd {
        display: initial;
        &::after {
          content: '\A';
          white-space: pre;
        }
      }
    }
    .actions {
      padding: 0;
      border: none;
      a {
        @include open-sans-condensed-bold-uppercase;
        padding: 14px 30px;
        margin-right: 18px;
        border: none;
        outline: none;
        color: white;
        background-color: $form-button-enabled;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .table-responsive {
    padding: 10px;
    @media screen and (max-device-width: $breakpoint-sm) {
      padding: 4px;
    }
    &.gray {
      background-color: $brand-gray-light;
    }
    table {
      font-size: 16px;
      margin-bottom: 0;
      @media screen and (max-device-width: $breakpoint-sm) {
        font-size: 14px;
      }
      th {
        background-color: white;
        @include open-sans-semibold;
        color: black;
        a {
          vertical-align: bottom;
        }
      }
      th, td {
        border: none;
        white-space: nowrap;
        @media screen and (max-device-width: $breakpoint-sm) {
          padding: 12px 4px;
        }
        a {
          display: inline-block;
          white-space: nowrap;
          color: $form-color-link-primary;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
      background-color: $brand-gray-light;
    }
    .table-striped > tbody > tr:nth-child(2n) > td, .table-striped > tbody > tr:nth-child(2n) > th {
      background-color: white;
    }
  }
}

