.HeroFeatures {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 500px;
  margin-right: $spacing-m;
  background-position: center;
  background-blend-mode: normal;
  background-repeat: no-repeat;
  padding: 40px;
  padding-bottom: $spacing-s;
  &.bottom-right-background-image-position {
    background-position-x: right;
    background-position-y: bottom;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      @include open-sans-light;
      font-weight: 400 !important;
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 10px;
      padding-left: 1em;
      text-indent: -.7em;
      &::before {
        display: inline-block;
        position: relative;
        top: 4px;
        left: 6px;
        width: 10px;
        content: "• ";
        color: black;
        font-size: 28px;
      }
    }
  }
  span {
    &::after {
      content: "";
      width: 50px;
      height: 1px;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      margin-left: 8px;
      background-color: white;
    }
  }
}

@media screen and (max-device-width: $breakpoint-sm) {
  .HeroFeatures {
    position: initial;
    max-width: 100%;
    margin-top: 95px;
    margin-right: 0;
    padding: $spacing-m $spacing-s;
    ul {
      li {
        font-size: 18px;
        margin-bottom: $spacing-s;
      }
    }
  }
}
