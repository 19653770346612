.FreeLeads {
  text-align: center;
  h2 {
    text-align: center;
  }
  .form-wrapper {
    background-color: $brand-gray-light;
    margin-bottom: 100px;
    .form-header {
      background-color: $brand-orange;
      p {
        @include open-sans-condensed-bold-uppercase;
        margin: 18px 20px;
        font-size: 32px;
        text-align: left;
      }
    }
    .form-data {
      padding: 30px 20px 40px 20px;
      input {
        border: solid 1px $input-border-color;
        display: inline-block;
        padding: 16px 20px;
        margin-bottom: 30px;
        width: 100%;
        &::placeholder {
          color: $brand-input-color;
        }
      }
      textarea {
        display: inline-block;
        width: 100%;
        height: 100px;
        &::placeholder {
          color: $brand-input-color;
        }
      }
      button {
        width: 100%;
        &.submit {
          @include open-sans-condensed-bold-uppercase;
          width: 100%;
          @media screen and (max-device-width: $breakpoint-sm) {
            width: auto;
          }
          max-width: 470px;
          border: 0;
          padding: 18px 56px;
          font-size: 20px;
          background-color: $brand-link-blue-dark;
          color: white;
        }
      }
    }
  }
}
