.RegionalProjects {
  .back-link-wrapper {
    margin-top: $spacing-l;
    @media screen and (max-device-width: $breakpoint-xs) {
      margin-top: $spacing-s;
    }
  }
  .description {
    @include open-sans-light;
  }
  .intro {
    img {
      margin-top: $spacing-xl;
    }
  }
  .custom-offset-lg-2 {
    position: relative;
    left: 6%;
    @media screen and (max-device-width: $breakpoint-xs) {
      left: initial;
    }
    a, button {
      &:hover {
        color: $brand-gray-darkest;
      }
      width: 100%;
      max-width: 450px;
      border-style: none;
      color: $brand-gray-darkest;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: $spacing-l;
      padding: $spacing-s * 1.5 0;
      background-color: $brand-orange;
      @include open-sans-condensed-bold-uppercase;
      font-size: 32px;
      line-height: 1;
      text-decoration: none;
      text-align: center;
      @media screen and (max-device-width: $breakpoint-xs) {
        width: 100%;
      }
      .arrow-right {
        margin-left: $spacing-m;
      }
    }
  }
  .bullet-points {
    p {
      @include open-sans-light;
      margin-bottom: 0;
    }
    ul {
      margin-top: 30px;
      margin-bottom: 30px;
      padding-left: 24px;
      list-style-type: none;
      li {
        @include open-sans-light;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 16px;
        @media screen and (max-device-width: $breakpoint-xs) {
          font-size: 16px;
          line-height: 20px;
        }
        &::before {
          position: absolute;
          left: 16px;
          content: "• ";
          font-size: 36px;
          vertical-align: bottom;
          color: $brand-orange;
        }
      }
    }
    .HoverTile {
      padding-bottom: 65%;
      margin: auto;
      @media screen and (max-device-width: $breakpoint-xs) {
        margin-top: 20px;
      }
      &:hover {
        background-color: transparent;
      }
      .content {
        margin-top: 80px;
        @media screen and (max-device-width: $breakpoint-sm) {
        margin-top: 0;
        }
        .title {
            margin-bottom: 0;
            a {
            display: block;
            background-color: transparent;
            color: white;
            text-align: left;
            font-size: 46px;
            line-height: 1.25;
            padding-top: 0;
            padding-bottom: 0;
            &::after {
              content: "";
              display: none;
            }
            .brand-color {
              color: $brand-orange;
            }
          }
        }
        hr {
          width: 100px;
          margin: 30px 10px;
        }
      }
    }
  }
  form {
    ul {
      padding-left: 0;
      margin-bottom: $spacing-m;
      @include open-sans-semibold;
      li {
        display: inline;
        font-size: 16px;
        padding-right: 28px;
        label {
          margin-top: 16px;
        }
        input {
          margin-right: 8px;
        }
      }
    }
  }
  .LeadsBanner {
    height: 0;
    padding-bottom: 65%;
    color: white;
    .content {
      padding-top: 95px;
      margin-left: $spacing-m;
      .title {
        @include open-sans-condensed-bold-uppercase;
        font-size: 46px;
        line-height: 1.25;
        .brand-color {
          color: $brand-orange;
        }
      }
    }
  }
}
