$hero-carousel-height: 420px;
.HeroCarousel {
  position: relative;
  color:  white;
  width: 100%;
  height: $hero-carousel-height;
  .my-slide {
    height: $hero-carousel-height;
    background-size: cover;
    background-color: rgba(0,0,0, .5);
    background-blend-mode: color;
    width: 100vw;
    background-position: 0;
  }
  .logo {
    display: none;
    @media screen and (max-device-width: $breakpoint-sm) {
      display: block;
      position: absolute;
      top: 0;
      left: 32px;
      width: 64px;
      height: 64px;
      z-index: 100;
      a {
        padding: 0;
        background-color: transparent;
        &::after {
          content: none;
        }
        svg {
          width: 64px;
          height: 64px;
        }
      }
    }
  }
  .slick-list {
    padding: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .slick-track {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .title {
    @include open-sans-condensed-bold-uppercase;
    font-size: 56px;
    white-space: nowrap;
    margin-bottom: 32px;
    padding-top: 100px;
  }
  .description {
    @include open-sans-light;
    font-size: 22px;
    margin-bottom: 36px;
  }
  a {
    @include open-sans-condensed-bold-uppercase;
    display: inline-block;
    background-color: $brand-blue;
    font-size: 16px;
    color: white;
    text-decoration: none;
    padding: 16px 24px;
    &:hover {
      color: white;
    }
    &::after {
      content: "";
      width: 30px;
      height: 1px;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      margin-left: 8px;
      background-color: white;
    }
  }
  .slick-dots {
    padding-bottom: $spacing-l;
    button::before {
      font-size: 12px;
      color: white;
      opacity: 1;
    }
    .slick-active button::before {
      color: $brand-orange;
    }
  }
  @media screen and (max-device-width: $breakpoint-sm) {
    height: auto;
    .title {
      font-size: 28px;
      white-space: normal;
      line-height: 1.2em;
      min-height: 170px;
      padding-top: 100px;
      margin-bottom: $spacing-s;
    }
    .description {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 20px;
    }
    .my-slide {
      height: auto;
    }
    .slick-dots {
      top: 390px;
    }
  }

  @media screen and (max-device-width: $breakpoint-md) {
    display: none;
    .slick-slider {
      margin-bottom: 0;
    }
    &.HomepageCarousel {
      display: inherit;
    }
  }
}
