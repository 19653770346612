// Breakpoints
$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200p;

// Non bootstrap compliant
$breakpoint-xxs: 375px;

@media screen and (max-device-width: $breakpoint-xs) {
  $spacing-xl: 48px;
  $spacing-l: 36px;
  $spacing-m: 24px;
  $spacing-s: 12px;
  $spacing-xs: 8px;
  $spacing-xxs: 4px;
}

$spacing-xl: 72px;
$spacing-l: 52px;
$spacing-m: 32px;
$spacing-s: 16px;
$spacing-xs: 8px;
$spacing-xxs: 4px;

$spacing-20: 20px;
$spacing-24: 24px;
$spacing-40: 40px;

$navbar-padding: 72px;
