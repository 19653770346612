.OurPartners {
  text-align: center;
  background-color: #ececec;
  padding-top: $spacing-m;
  .partners-text {
    font-size: 14px;
    margin-bottom: 0;
  }
  .slick-dots {
    position: initial;
    padding-bottom: $spacing-l;
    button::before {
      font-size: 12px;
      color: white;
      opacity: 1;
    }
    .slick-active button::before {
      color: $brand-orange;
    }
  }
}
