.TopBar {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-end;
  background-color: transparent;
  @media screen and (max-device-width: $breakpoint-sm) {
    background-color: $navbar-mobile-background;
  }
  z-index :1000;
  @media screen and (max-device-width: $breakpoint-sm) {
    height: $navbar-padding;
    &.hide-on-home {
        display: none;
    }
  }
  .logo {
    position: absolute;
    top: 0;
    left: 40px;
    @media screen and (max-device-width: $breakpoint-sm) {
      left: 32px;
      svg {
        width: 64px;
        height: 64px;
      }
    }
  }
  ul {
    &.links {
      display: flex;
      @media screen and (max-device-width: $breakpoint-sm) {
        display: block;
      }

      height: 50px;
      margin-bottom: 0;
      padding-left: 0;
      list-style-type: none;
      li {
        display: flex;
        align-items: center;
        padding: 0 25px;
        font-size: 16px;
        @media screen and (max-device-width: $breakpoint-xs) { font-size: 14px; padding: 0 8px; }
        a {
          color: $brand-gray-darkest;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        &.leads {
          background-color: $brand-orange;
          padding: 0 18px;
          font-size: 24px;
          @media screen and (max-device-width: $breakpoint-xs) { font-size: 14px; padding: 0 8px; }
          a {
            @include open-sans-condensed-bold-uppercase;
            color: white;
          }
        }
        &.phone {
          @include open-sans-condensed-bold;
          font-weight: bold;
          font-size: 20px;
          @media screen and (max-device-width: $breakpoint-xs) { font-size: 14px; padding: 0 8px; }
        }
      }
    }
    &.navbar-nav {
      background-color: $navbar-mobile-background;
    }
  }
  .Menu {
    right: 0;
    border-right: 0;
    .navbar-nav {
      padding-right: 8px;
      padding-bottom: 8px;
      a {
        text-decoration: none;
      }
    }
  }
}
