.NationalProjects {
  .back-link-wrapper {
    margin-top: $spacing-l;
    @media screen and (max-device-width: $breakpoint-xs) {
      margin-top: $spacing-s;
    }
  }
  .intro {
    img {
      margin-top: $spacing-xl;
    }
  }
  .custom-offset-lg-2 {
    position: relative;
    left: 6%;
    @media screen and (max-device-width: $breakpoint-xs) {
      left: initial;
    }
    a {
      color: white;
      &::after {
        content: none;
      }
      &.brand-link {
        &:hover {
          color: $brand-gray-darkest;
        }
          color: $brand-gray-darkest;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: $spacing-l;
          padding: $spacing-s * 1.5 0;
          background-color: $brand-orange;
          @include open-sans-condensed-bold-uppercase;
          font-size: 32px;
          line-height: 1;
          text-decoration: none;
          text-align: center;
          .arrow-right {
            margin-left: $spacing-m;
        }
      }
    }
    hr {
      width: 100px;
      margin-left: 10px;
    }
  }
  .bullet-points {
    p {
      @include open-sans-light;
      margin-bottom: 0;
    }
    ul {
      margin-top: 30px;
      margin-bottom: 30px;
      padding-left: 24px;
      list-style-type: none;
      li {
        @include open-sans-light;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 16px;
        @media screen and (max-device-width: $breakpoint-xs) {
          font-size: 16px;
          line-height: 20px;
        }
        &::before {
          position: absolute;
          left: 16px;
          content: "• ";
          font-size: 36px;
          vertical-align: bottom;
          color: $brand-orange;
        }
      }
    }
    .HoverTile {
      padding-bottom: 65%;
      margin: auto;
      @media screen and (max-device-width: $breakpoint-xs) {
        margin-top: 20px;
      }
      &:hover {
        background-color: transparent;
      }
      .content {
        margin-top: 95px;
        .title {
          font-size: 46px;
          line-height: 1.25;
          .brand-color {
            color: $brand-orange;
          }
        }
      }
    }
  }
}
