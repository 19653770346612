.TestimonialCard {
  padding: $spacing-s;
  background-color: white;
  max-width: 840px;
  max-width: 65vw;
  .big-quote {
    font-size: 70px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
    color: $brand-quote-blue-dark;
    line-height: 90px;
  }
  .text {
    @include open-sans-light;
    font-style: italic;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 0;
  }
  .author {
    @include open-sans-condensed-bold-uppercase;
    margin-top: $spacing-m;
    color: $brand-blue-faq;
    font-size: 16px;
    margin-bottom: 0;
  }
}

@media only screen and (max-device-width: $breakpoint-xs) {
  .TestimonialCard {
    .text {
      font-size: 16px;
      line-height: 32px;
    }
    .author {
      font-size: 16px;
      line-height: 1.2em;
    }
  }
}
