$base-color: #0a0a0a;
body {
  font-family: 'Open Sans';
  color: $base-color;
}

@mixin open-sans-light {
  font-family: 'Open Sans' !important;
  font-weight: 300 !important;
}

@mixin open-sans-semibold {
  font-family: 'Open Sans' !important;
  font-weight: 600 !important;
}

@mixin open-sans-condensed-bold {
  font-family: 'Open Sans Condensed' !important;
  font-weight: bold !important;
}

@mixin open-sans-condensed-bold-uppercase {
  @include open-sans-condensed-bold;
  text-transform: uppercase;
}

h1, h2, h3, h4 {
  @include open-sans-condensed-bold-uppercase;
}
h1 {
  font-size: 46px;
  margin-top: $spacing-xl !important;
  margin-bottom: $spacing-l !important;
}
h2 {
  font-size: 32px !important;
  margin-top: $spacing-l !important;
  margin-bottom: $spacing-m !important;
}
h3 {
  font-size: 22px !important;
}

h4 {
  font-size: 16px !important;
}

p {
  font-size: 18px;
  line-height: 28px;
  font-weight: 300;
  margin-bottom: $spacing-l;
  &.large {
    font-size: 22px;
    line-height: 36px;
  }
}

a{
  color: $base-color;
  text-decoration: underline;
}

@media screen and (max-device-width: $breakpoint-xs) {
  h1 {
    font-size: 32px;
    margin-bottom: 30px;
  }
  h2 {
    font-size: 26px;
    margin-bottom: 18px;
  }
  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 28px;
    &.large {
      font-size: 20px;
      line-height: 32px;
    }
  }
}
