$max-column-width: 150px;
.Projects {
  margin-bottom: $spacing-xl;
  h1 {
    @include open-sans-condensed-bold-uppercase;
    color: $brand-gray-darkest;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0;
    @media screen and (max-device-width: $breakpoint-sm) {
      font-size: 26px;
    }
  }

  .table-responsive {
    padding: 10px;
    @media screen and (max-device-width: $breakpoint-sm) {
      padding: 4px 0;
      border: none;
    }
    &.actions {
      padding: 0;
      margin: 30px 0;
      display: flex;
      justify-content: space-between;
      border: none;
      button, a.filter {
        @include open-sans-condensed-bold-uppercase;
        @media screen and (max-device-width: $breakpoint-sm) {
          margin: 0 30px;
        }
        padding: 14px 30px;
        border: none;
        outline: none;
        color: white;
        background-color: $form-button-enabled;
        &.disabled {
          background-color: $form-button-disabled;
        }
      }
    }
    &.gray {
      background-color: $brand-gray-light;
    }
    table {
      font-size: 16px;
      @media screen and (max-device-width: $breakpoint-sm) {
        font-size: 14px;
      }
      th, th.active {
        background-color: $brand-orange;
        @include open-sans-condensed-bold-uppercase;
        color: black;
        a {
          vertical-align: bottom;
        }
      }
      th, td {
        &:nth-child(2) {
          @media screen and (max-device-width: $breakpoint-xs) {
            padding-left: $max-column-width + 8px;
            min-width: 300px;
          }
        }
        &:first-of-type {
          @media screen and (max-device-width: $breakpoint-xs) {
            box-shadow: 5px 0 5px rgba(0, 0, 0, 0.125);
            position: absolute;
            width: $max-column-width;
          }
        }
        border: none;
        white-space: nowrap;
        @media screen and (max-device-width: $breakpoint-sm) {
          padding: 12px 4px;
        }
        &.project-location {
          @media screen and (max-device-width: $breakpoint-sm) {
            @include ellipsis(90px);
          }
        }
        a, .project-location {
          display: inline-block;
          white-space: nowrap;
          @media screen and (max-device-width: $breakpoint-sm) {
            @include ellipsis($max-column-width - 10px);
          }
          color: $form-color-link-primary;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .table-striped > tbody > tr:nth-child(2n+1) > td,
    .table-striped > tbody > tr:nth-child(2n+1) > th {
      background-color: $brand-gray-light;
    }
    .table-striped > tbody > tr:nth-child(2n) > td,
    .table-striped > tbody > tr:nth-child(2n) > th {
      background-color: white;
    }
  }
}

