.BackLink {
  display: flex;
  align-items: center;
  height: 18px;
  margin-top: 15px;
  svg {
    display: inline-block;
    height: 90%;
    path {
      stroke: $brand-link-blue-dark;
    }
  }
  a {
    @include open-sans-condensed-bold-uppercase;
    padding-left: 10px;
    font-size: 16px;
    text-decoration: none;
    color: $brand-link-blue-dark;
    &:hover {
      text-decoration: underline;
    }
  }
}
