.SignInSignUp {
  text-align: center;
  h2 {
    text-align: center;
  }
  label {
    text-align: left;
    width: 100%;
  }
  .form-wrapper {
    background-color: $brand-gray-light;
    margin-bottom: 100px;
    .form-header {
      background-color: $brand-orange;
      p {
        @include open-sans-condensed-bold-uppercase;
        margin: 18px 20px;
        font-size: 32px;
        text-align: left;
      }
    }
    .form-data {
      padding: 30px 20px 40px 20px;
      .checkbox-wrapper {
        position: relative;
        left: 10px;
        label {
          font-size: 13px;
          width: auto;
          padding-left: $spacing-xs;
        }
        input {
          @include scale(1.5);
          width: auto;
        }
      }
      .forgotten-password-wrapper {
        font-size: 13px;
        padding-top: 2px; //Aligning with checkbox/label
        a {
          color: $form-color-forgotten-password;
        }
      }
      .input-wrapper {
        margin-bottom: 30px;
        input {
          @include textInput;
        }
      }
      textarea {
        display: inline-block;
        width: 100%;
        height: 100px;
        &::placeholder {
          color: $brand-input-color;
        }
      }
      button {
        width: 100%;
        &.submit {
          @include open-sans-condensed-bold-uppercase;
          width: 100%;
          max-width: 470px;
          border: 0;
          padding: 18px 56px;
          font-size: 20px;
          background-color: $brand-link-blue-dark;
          color: white;
        }
      }
      .recaptcha-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 40px;
      }
    }
  }
}
