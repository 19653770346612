@mixin scale($factor) {
  -ms-transform: scale($factor); /* IE */
  -moz-transform: scale($factor); /* FF */
  -webkit-transform: scale($factor); /* Safari and Chrome */
  -o-transform: scale($factor); /* Opera */
  transform: scale($factor);
}

@mixin ellipsis($maxWidth) {
  max-width: $maxWidth;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin orangeHeader() {
  background-color: $brand-orange;
  padding: 18px;
  margin: 10px;
}
@mixin textInput() {
  border: solid 1px $input-border-color;
  display: inline-block;
  padding: 16px 20px;
  width: 100%;
  &::placeholder {
    color: $brand-input-color;
  }
}
