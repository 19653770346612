$text-color: white;
$background-color: #101010;
.NavigationFooter {
  text-align: center;
  padding: $spacing-xl 0;
  background-color: $background-color;
  color: $text-color;
  font-size: 20px;
  line-height: 40px;
  @media screen and (max-device-width: $breakpoint-xs) {
    font-size: 16px;
    line-height: 32px;
  }
  @media screen and (max-device-width : 320px) {
    font-size: 14px;
  }
  ul {
    max-width: 1000px;
    padding: 0;
    margin: 0 auto;
    list-style-type: none;
    li {
      display: inline;
      &::before {
        content: ' ';
      }
      &::after {
        content: ' ';
        word-spacing: 1em;
        background-image: linear-gradient($text-color, $text-color);
        background-size: .15ch 1em;
        background-repeat: no-repeat;
        background-position: center center;
      }
      a {
        color: $text-color;
        text-decoration: none;
        white-space: nowrap;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-device-width: $breakpoint-xs) {
  .NavigationFooter {
    padding: 36px 0;
  }
}
