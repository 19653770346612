.FAQAnswer {
  .answer {
    margin-bottom: $spacing-l;
    p {
      @include open-sans-light;
      &.back {
        display: flex;
        align-items: center;
        font-size: 16px;
        margin: $spacing-xl 0;
        svg {
          display: inline-block;
          width: 1ch;
          path {
            stroke: $brand-link-blue-dark;
          }
        }
        a {
          @include open-sans-condensed-bold-uppercase;
          padding-left: 10px;
          text-decoration: none;
          color: $brand-link-blue-dark;
          &:hover {
          text-decoration: underline;
          }
        }
      }
    }
    ul {
      li {
        @include open-sans-light;
        font-size: 18px;
      }
    }
  }
}
