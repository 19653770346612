.OurPartnersSimple {
  margin: 80px auto;
  @media screen and (max-device-width: $breakpoint-xs) {
    margin: 20px auto;
  }
  .title {
    text-align: center;
  }
  .holder {
    display: flex;
    white-space: nowrap;
    margin: auto;
    overflow: auto;
    img {
      display: inline;
      align-self: center;
      margin: 8px 20px;
      max-width: 200px;
      &.grow {
        min-height: 100px;
      }
    }
  }
}
