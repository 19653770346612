.FAQS {
  .questions {
    padding-bottom: $spacing-xl;
  }
  ul {
    @include open-sans-light;
    padding-left: 0;
    list-style-type: none;
    font-size: 18px;
    li {
      margin-bottom: 24px;
      a {
      text-decoration: none;
      font-weight: initial;
      color: $brand-blue-faq;
      &:hover {
        text-decoration: underline;
      }
      }
    }
  }
}
