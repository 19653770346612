.ContactUs {
  text-align: center;
  h2 {
    text-align: center;
  }
  p {
    margin-bottom: $spacing-m;
  }
  .contact-data {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content:space-around;
    background-color: $brand-gray-light;
    width: 100%;
    padding: 45px 0;
      @media screen and (max-device-width: $breakpoint-xs) {
        padding: 24px 0;
      }
    p {
      font-size: 26px;
      line-height: 42px;
      font-weight: 600;
      margin-bottom: 0;
      @media screen and (max-device-width: $breakpoint-xs) {
        font-size: 18px;
      }
      a {
        color: black;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      svg {
        @media screen and (max-device-width: $breakpoint-xs) {
          display: block;
          margin: 8px auto 0 auto;
        }
      }
    }
  }
  .form-data {
    margin: 20px 0 40px 0;
    input {
      border: solid 1px $brand-input-color;
      padding: 16px 20px;
      display: inline-block;
      margin-bottom: 30px;
      width: 100%;
      &::placeholder {
        color: $brand-input-color;
      }
    }
    textarea {
      display: inline-block;
      width: 100%;
      height: 100px;
      padding: 16px 20px;
      border: solid 1px $brand-input-color;
      &::placeholder {
        color: $brand-input-color;
      }
    }
    button {
      &.submit {
        @include open-sans-condensed-bold-uppercase;
        margin: 45px;
        border: 0;
        padding: 18px 56px;
        font-size: 20px;
        background-color: $brand-orange;
        color: $brand-quote-blue-dark;
        &::placeholder {
          color: $brand-input-color;
        }
      }
    }
  }
}
