$background-color: #eeeeee;
$bar-height: 60px;
$z-index-high: 100;
$z-index-low: 1;

.TickerBar{
  width: 100%;
  display: flex;
  overflow: hidden;

  @keyframes ticker {
    100% {
      transform: translate3d(-100%, 0, 0);
    }
  }
  &__header {
    display: flex;
    align-items: center;
    white-space: nowrap;
    background-color: $brand-orange;
    p {
      @include open-sans-condensed-bold-uppercase;
      font-size: 18px;
      padding: $spacing-s;
      z-index: $z-index-high;
      background-color: $brand-orange;
      color: black;
      margin-bottom: 0;
    }
    svg {
      height: $bar-height;
      z-index: $z-index-high;
      background-color: $background-color;
      path {
        fill: $brand-orange;
      }
    }
  }

  &__wrapper {
    flex: 1;
    background-color: $background-color;
  }

  &__move {
    @include open-sans-light;
    z-index: $z-index-low;
    display: flex;
    height: $bar-height;
    align-items: center;
    white-space: nowrap;
    padding-right: 100%;
    animation-timing-function: linear;
    animation: ticker 40s infinite;
    span {
      font-weight: 600;
    }
    &:hover {
      animation-play-state: paused;
    }
  }

  &__item {
    display: inline-block;
    padding: 0 20px;
    font-size: 16px;
    a {
      text-decoration: none;
      :hover {
        text-decoration: underline;
      }
    }
  }
  @media screen and (max-device-width: $breakpoint-xxs) {
    display: block;
    width: 100%;
    &__header {
      display: block;
      p {
        font-size: 18px;
        padding-left: 30px;
      }

    }
  }
}
