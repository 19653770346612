.featured-content {
  margin: 40px 0;
  @media screen and (max-device-width: $breakpoint-xs) {
  margin: 20px 0;
  }
  p {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 18px;
    @media screen and (max-device-width: $breakpoint-xs) {
      font-size: 18px;
      line-height: 18px;
    }
  }
  a {
    color: black;
    text-decoration: underline;
  }
}
