.SimpleTile {
  background-size: cover;
  width: 450px;
  height: 293px;
  margin-bottom: 20px;
  padding-top: 100px;
  padding-left: 38px;
  font-size: 46px;
  @media screen and (max-device-width: $breakpoint-xs) {
    width: 350px;
    height: 183px;
    padding-top: 40px;
    padding-left: 18px;
    font-size: 32px;
  }
  a {
    @include open-sans-condensed-bold;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    color: black;
  }
  hr {
    margin-left: 0;
    width: 100px;
    border: none;
    border-top: solid 1px black;
  }
}
