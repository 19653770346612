$first-column-width: 200px;
.Products {
  margin-bottom: $spacing-xl;
  h1 {
    @include open-sans-condensed-bold-uppercase;
    color: $brand-gray-darkest;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0;
    @media screen and (max-device-width: $breakpoint-sm) {
      font-size: 26px;
    }
  }

  .table-responsive {
    padding: 10px;
    @media screen and (max-device-width: $breakpoint-sm) {
      padding: 4px 0;
      border: none;
    }
    &.gray {
      background-color: $brand-gray-light;
    }
    table {
      margin-bottom: 0;
      text-align: center;
      font-size: 16px;
      border-collapse: collapse;
      @include open-sans-condensed-bold-uppercase;
      font-size: 16px;

      @media screen and (max-device-width: $breakpoint-sm) {
        font-size: 14px;
      }
      th {
        border: 1px solid $brand-gray-light;
        text-align: center;
        &.big {
          background-color: $brand-orange;
          font-size: 22px;
          span {
            display: block;
            font-size: 12px;
          }
        }
        background-color: $brand-orange-light;
        color: black;
      }
      th, td {
        white-space: nowrap;
        min-width: 100px;
        padding: 12px 0;
        @media screen and (max-device-width: $breakpoint-sm) {
          padding: 12px 4px;
        }
        &:first-of-type {
          @media screen and (max-device-width: $breakpoint-xs) {
            box-shadow: 5px 0 5px rgba(0, 0, 0, 0.125);
            position: sticky;
            left: 0;
            white-space: initial;
            min-width: $first-column-width;
            width: $first-column-width;
          }
        }
        &:nth-child(2) {
          @media screen and (max-device-width: $breakpoint-xs) {
            // padding-left: $first-column-width + 8px;
          }
        }
        &.feature {
          text-align: right;
          padding-right: 20px;
          text-transform: none;
        }
        .brand-orange {
          color: $brand-orange;
        }
        a {
          cursor: pointer;
          color: $brand-orange;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
          &.select-plan {
            display: inline-block;
            @include open-sans-condensed-bold-uppercase;
            @media screen and (max-device-width: $breakpoint-sm) {
              margin: 0 30px;
            }
            padding: 14px 30px;
            border: none;
            outline: none;
            color: white;
            background-color: $form-button-enabled;
            &.disabled {
              background-color: $form-button-disabled;
            }
          }
          &.more-info {
            display: block;
            margin: 12px 0 4px 0; //Compensating cell padding
            text-transform: none;
          }
        }
      }
      td {
        border: none;
      }
      tfoot {
        border: 1px solid $brand-gray-light;
        tr {
          .note {
            @include open-sans-light;
            background-color: white;
            padding-top: 28px;
            padding-right: 20px;
            text-align: right;
            text-transform: none;
            font-size: 12px;
            @media screen and (max-device-width: $breakpoint-xs) {
              white-space: normal;
            }
          }
          background-color: white;
        }
      }
    }
    &.gray {
      background-color: $brand-gray-light;
    }
    .table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
      background-color: $brand-gray-light;
    }
    .table-striped > tbody > tr:nth-child(2n) > td, .table-striped > tbody > tr:nth-child(2n) > th {
      background-color: white;
    }
  }
}
.modal-dialog.modal-dialog-lg {
  width: 70%;
  max-width: 850px;
  margin:  40px auto;
  @media screen and (min-device-width: $breakpoint-lg) {
    margin:  80px auto;
    width: 835px;
  }
  @media screen and (min-device-width: $breakpoint-md) and (max-device-width: $breakpoint-lg) {
    margin:  60px auto;
    width: 700px;
  }

  @media screen and (max-device-width: $breakpoint-md) {
    margin:  30px auto;
    width: 90%;
  }

  .product-modal {
    .modal-header {
      border-bottom: none;
      button {
        position: relative;
        top: 8px;
        right: 8px;
        opacity: 1;
        color: $brand-text-dark;
        font-weight: 400;
      }
    }
    .modal-body {
      @media screen and (min-device-width: $breakpoint-md) {
        padding: 30px 50px 50px 50px;
      }
      @media screen and (max-device-width: $breakpoint-md) {
        padding: 10px 20px 20px 20px;
      }
      p {
        @include open-sans-light;
        color: $brand-text-dark;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 16px;
      }
    }
  }
}

