.IconCardLeft {
  margin: 0 8px;
  max-width: 450px;
  max-height: 200px;
  @media screen and (max-device-width: $breakpoint-md) {
    margin: 0 0 4px 0;
  }
  padding: 30px 20px 30px 45px;
  background-color: $brand-gray-light;
  display: flex;
  flex-direction: row;
  .icon {
    width: 100px;
  }
  .text {
    padding-left: 30px;
    .title {
        margin-bottom: 20px;
        a {
        margin-top: 22px;
        margin-bottom: 0;
        @include open-sans-condensed-bold-uppercase;
        font-size: 32px;
        color: $brand-gray-darkest;
        &:hover {
          text-decoration: none;
        }
        &::after {
          content: "";
          width: 30px;
          height: 1px;
          position: relative;
          display: inline-block;
          vertical-align: middle;
          margin-left: 16px;
          background-color: $brand-orange;
        }
      }
    }
    .subtitle a {
      &:hover {
        text-decoration: none;
      }
      @include open-sans-light;
      margin-bottom: 0;
      font-size: 18px;
      line-height: 28px;
      color: $brand-gray-darkest;
    }
  }
}
@media only screen and (max-device-width: $breakpoint-xs) {
  .IconCardLeft {
    width: 280px;
    height: 150px;
    padding: 0;
    align-items: center;
    margin: 0 10px;
    .icon {
      width: 68p;
      height: 94px;
      padding-left: 18px;
    }
    .text {
      padding-left: 18px;
      .title {
        margin-top: 32px;
        margin-bottom: 18px;
        a {
          font-size: 22px;
          text-decoration: none;
          &:hover {
            text-decoration: none;
          }
        }
      }
      .subtitle {
        padding-right: 12px;
        a {
          font-size: 14px;
          line-height: 20px;
          text-decoration: none;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}
