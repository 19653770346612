.Testimonials {
  background-color: white;
  text-align: center;
  p.intro {
    font-size: 22px;
    line-height: 36px;
    margin-bottom: 52px;
  }
  .slider {
    background-color: $brand-gray-light;
    margin-bottom: 0;
    .my-slide {
      padding: $spacing-l;
      background-color: white;
      max-width: 840px;
      width: 65vw;
      margin: 50px 50px 36px 50px;
      outline: none;
      @media screen and (min-device-width: $breakpoint-xs) and (max-device-width: $breakpoint-md) {
        width: 75vw;
        margin: 20px;
      }
      @media only screen and (max-device-width: $breakpoint-xs) {
        width: 80vw;
        margin: 16px;
        padding: 24px;
      }
      .partner-name {
        margin-top: $spacing-m;
        @media only screen and (max-device-width: $breakpoint-xs) {
        }
      }
      .partner-text {
        font-size: 16px;
        line-height: 28px;
        font-weight: 300;
        margin-bottom: 0;
        @media only screen and (max-device-width: $breakpoint-xs) {
          font-size: 14px;
          line-height: 22px;
        }
      }
      .partner-logo {
        display: block;
        margin: auto;
      }
    }
    .slick-dots {
      position: initial;
      padding-bottom: $spacing-l;
      button::before {
        font-size: 12px;
        color: white;
        opacity: 1;
      }
      .slick-active button::before {
        color: $brand-orange;
      }
    }
  }
}
